(function($) {
  function showPrice(price) {
    // Price display
    $('.pricing-pack-monthly').addClass('sr-only');
    $('.pricing-pack-annually').addClass('sr-only');
    $('.pricing-pack-'+price).removeClass('sr-only');
  }

  const priceSelector = $('input[name="priceSwitch"]');
  let price = localStorage.getItem('price');

  if (price === null) {
    price = 'monthly';
    localStorage.setItem('price', price);
  } else {
    $('#priceSwitch-'+price).prop('checked', true);
  }

  $('#price-switch-label-'+price).addClass('active');
  showPrice(price);

  priceSelector.on('change', function() {
    price = $(this).val();
    localStorage.setItem('price', price);
    showPrice(price);
  });
})(jQuery);
