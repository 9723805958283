(function($) {
  var annonceurWeight = 0;
  var site1Weight = 0;
  var site2Weight = 0;
  var site3Weight = 0;
  var scn2Weight = 0;
  var scn3Weight = 0;
  var scn4Weight = 0;
  var scn5Weight = 0;

  function calculateCommissions() {
    annonceurWeight = parseInt($('input[name="annonceur"]:checked').val());
    site1Weight = parseInt($('input[name="site1"]:checked').val());
    site2Weight = parseInt($('input[name="site2"]:checked').val());
    site3Weight = parseInt($('input[name="site3"]:checked').val());

    //scenario2
    scn2Weight = annonceurWeight+site1Weight;
    $('#annonceur-sc2-amount').html(Math.round(((annonceurWeight*10)/scn2Weight)*100)/100);
    $('#site1-sc2-amount').html(Math.round(((site1Weight*10)/scn2Weight)*100)/100);

    //scenario3
    scn3Weight = annonceurWeight+site1Weight+site2Weight;
    $('#annonceur-sc3-amount').html(Math.round(((annonceurWeight*10)/scn3Weight)*100)/100);
    $('#site1-sc3-amount').html(Math.round(((site1Weight*10)/scn3Weight)*100)/100);
    $('#site2-sc3-amount').html(Math.round(((site2Weight*10)/scn3Weight)*100)/100);

    //scenario4
    scn4Weight = annonceurWeight+site1Weight+site2Weight+site3Weight;
    $('#annonceur-sc4-amount').html(Math.round(((annonceurWeight*10)/scn4Weight)*100)/100);
    $('#site1-sc4-amount').html(Math.round(((site1Weight*10)/scn4Weight)*100)/100);
    $('#site2-sc4-amount').html(Math.round(((site2Weight*10)/scn4Weight)*100)/100);
    $('#site3-sc4-amount').html(Math.round(((site3Weight*10)/scn4Weight)*100)/100);

    //scenario5
    scn5Weight = site1Weight+site2Weight+site3Weight;
    $('#site1-sc5-amount').html(Math.round(((site1Weight*10)/scn5Weight)*100)/100);
    $('#site2-sc5-amount').html(Math.round(((site2Weight*10)/scn5Weight)*100)/100);
    $('#site3-sc5-amount').html(Math.round(((site3Weight*10)/scn5Weight)*100)/100);
  }

  calculateCommissions();

  $('input[name="annonceur"], input[name="site1"], input[name="site2"], input[name="site3"]').on('change', function() {
    calculateCommissions();
  });
})(jQuery);
