// Remove types from Documentation pages' breadcrumbs
(jQuery)('.breadcrumbs > span').each(function () {
  if ((jQuery)(this).prop('property', 'itemListElement')) {
    if ((jQuery)(this).children('a.taxonomy').length > 0) {
      (jQuery)(this).get(0).nextSibling.remove(); // to remove the following text node (" > ")
      (jQuery)(this).remove();
    }
  }
});

/* global gtag */
function gtag_report_contact_conversion(url) {
  var callback = function () {
    if (typeof (url) !== 'undefined') {
      window.location = url;
    }
  };

  if (typeof gtag === 'function') {
    gtag('event', 'conversion', {
      'send_to': 'AW-943132239/PuGXCMD08YIBEM-c3MED',
      'event_callback': callback,
    });
  }

  return false;
}

function gtag_report_demo_conversion(url) {
  var callback = function () {
    if (typeof (url) !== 'undefined') {
      window.location = url;
    }
  };

  if (typeof gtag === 'function') {
    gtag('event', 'conversion', {
      'send_to': 'AW-943132239/-EADCIXag50BEM-c3MED',
      'event_callback': callback,
    });
  }

  return false;
}

import 'tether';
import 'bootstrap';
import svg4everybody from 'svg4everybody';
import 'slick-carousel';

import './menu';
import './simulator';
import './tooltip';
import './currency-selector';
import './price-selector';
import './job-scroll';
import './slide';
import './copy-function';

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        document.addEventListener('wpcf7mailsent', function (event) {
          const ids = [1104, 1102, 2293, '4d9cf09', '744c888', 'fabd611'];
          if (ids.indexOf(event.detail.contactFormId) > -1) {
            gtag_report_demo_conversion();
            var url = 'https://v3.affilae.com/common/users.demoRequest';

            const formData = {
              'firstname': $('input[name="firstname"]').val(),
              'lastname': $('input[name="lastname"]').val(),
              'email': $('input[name="email"]').val(),
              'type': $('select[name="status"]').val(),
              'phone': $('input[name="phone"]').val(),
              'url': $('input[name="url"]').val(),
              'language': $('input[name="language"]').val(),
              'company': $('input[name="company"]').val(),
              'industry': $('select[name="sector"]').val(),
              'message': $('textarea[name="message"]').val(),
            };

            $.ajax({
              type: 'POST',
              url: url,
              data: formData,
            });
          }

          if (event.detail.contactFormId === 3 || event.detail.contactFormId === 1067) {
            gtag_report_contact_conversion();
          }
        }, false);

        svg4everybody();
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
