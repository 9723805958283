import $ from 'jquery';
// import Splide from '@splidejs/splide';

function debounce(func, timeout = 300){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

$(document).ready(function($) {
  $('.slick-slider-partners').slick({
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          arrows: false,
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 980,
        settings: {
          arrows: false,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 740,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
    ],
  });

  $('.slick-slider-software').slick({
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

  $('.slick-slider-employer').slick({
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: true,
    dots: false,
    infinite: true,
    centerMode: true,
    variableWidth: true,
  });

  $('.slick-slider-video').slick({
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: true,
    dots: false,
    infinite: true,
    centerMode: true,
    variableWidth: true,
  });

  //Slider categories blog
  //ajoute un tag autour du nombre de post par catégorie
  const categories = document.querySelectorAll('.cat-item');
  if (!categories.length) {
    return;
  }
  const regex = /(.*) (\(\d+\))/i;

  categories.forEach(function (category) {
    category.innerHTML = category.innerHTML.replace(regex, '$1 <span class="cat-item-posts-count">$2</span>');
  });

  //gère le style pour la catégorie 'all'
  const categoryAll = document.querySelector('.cat-item-all');
  categoryAll.classList.add('current-cat');
  categories.forEach(function (category) {
    if (category.classList.contains('current-cat')) {
      categoryAll.classList.remove('current-cat')
    }
  });

  //gère la position du scroll du slider
  const categoriesRightBtn = document.querySelector('.news-categories-list-control-right');
  const categoriesLeftBtn = document.querySelector('.news-categories-list-control-left');
  const categoriesList = document.querySelector('.news-categories-list');
  categoriesRightBtn.addEventListener("click", scrollRight);
  categoriesLeftBtn.addEventListener("click", scrollLeft);

  let categoriesStep = 150;

  let maxX = categoriesList.scrollWidth - categoriesList.clientWidth;

  window.addEventListener("resize", debounce(categoryScrollUpdate));
  categoryScrollUpdate();

  function categoryScrollUpdate() {
    if(categoriesList.scrollLeft === 0) {
      categoriesLeftBtn.classList.toggle('categories-control-disabled')
    }
    if(categoriesList.scrollLeft === maxX) {
      categoriesRightBtn.classList.toggle('categories-control-disabled')
    }
  }

  function scrollRight() {
    categoriesList.scrollBy({
      left: categoriesStep,
      behavior : "smooth",
  })
  categoryScrollUpdate()
  }

  function scrollLeft() {
    categoriesList.scrollBy({
      left: -categoriesStep,
      behavior : "smooth",
  })
  categoryScrollUpdate()
  }
});
