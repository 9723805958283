(function($) {
  var body = $('body');
  var hamburger = $('.hamburger');
  var showLeft = $('#showLeft');

  function disableOther( button ) {
    if( button !== 'showLeft' ) {
      showLeft.toggleClass('disabled');
    }
  }

  showLeft.on('click', function() {
    $(this).toggleClass('active');
    body.toggleClass('cbp-spmenu-open');
    disableOther('showLeft');
  });

  hamburger.on('click', function() {
    $(this).toggleClass('is-active');
  });
})(jQuery);

$(document).ready(function($) {
  $('.menu-item-has-children').hover(function() {
    $('#cbp-spmenu-s1').addClass('nav-primary-sub');
  },
  function(){
    $('#cbp-spmenu-s1').removeClass('nav-primary-sub');
  });
});
