(function($) {
  function showCurrency(currency) {
    $('.pricing-pack-single-block').addClass('sr-only');
    $('.pricing-pack-single-block-'+currency).removeClass('sr-only');

    $('.link-pack-pricing').addClass('sr-only');
    $('.link-pack-pricing-'+currency).removeClass('sr-only');
  }

  var currencySelector = $('#currency-selector');
  var userLang = navigator.language || navigator.userLanguage;
  var currency = '';

  if (localStorage.getItem('currency') === null) {
    if (userLang === 'en-US') {
      currency = 'dollar';
    } else if (userLang === 'en-GB') {
      currency = 'pound';
    } else {
      currency = 'euro';
    }

    localStorage.setItem('currency', currency);
  }

  currencySelector.val(localStorage.getItem('currency'));
  showCurrency(localStorage.getItem('currency'));

  currencySelector.on('change', function() {
    var currency = currencySelector.val();
    localStorage.setItem('currency', currency);
    showCurrency(currency);
  });
})(jQuery);
